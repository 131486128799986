<template>
  <div class="row">
    <div class="col-sm-12">
      <h3 class="mb-3">All Manufacturer Users</h3>

      <div class="py-6" v-if="loading">
        <table-skeleton> </table-skeleton>
      </div>

      <DataTable
        v-else
        :columns="[
          { field: 'first_name', label: 'First Name' },
          { field: 'last_name', label: 'Last Name' },
          {
            field: 'email',
            label: 'email',
          },
          {
            field: 'date_joined',
            label: 'Added',
            formatFn: createdFormatter,
          },
          { field: 'view', label: 'Action', sortable: false },
        ]"
        :rows="users"
        :totalRecords="totalUsers"
        :basePaginatorUrl="baseUserUrl"
        detailsRouteName="user-details"
        @fetch-next-page-results="getUsers"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import DataTable from "@/components/DataTable";

export default {
  name: "ManufacturerUserTable",
  components: { DataTable },
  data() {
    return {
      users: null,
      totalUsers: null,
      baseUserUrl: null,
      error: "",
      searchQuery: null,
      loading: true,
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers(
      clearUsers,
      baseUserUrl = `v1/auth/users?role=Manufacturer&is_active=true`
    ) {
      if (clearUsers) {
        this.users = [];
      }
      this.baseUserUrl = baseUserUrl;
      axios
        .get(baseUserUrl)
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.users = response.data.results;
            this.totalUsers = response.data.count;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    createdFormatter(value) {
      return new Date(value).toLocaleString();
    },
  },
};
</script>
<style></style>
