<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'manufacturers' }"
              >Manufacturers</router-link-active
            >
          </li>
        </ol>
      </nav>
    </div>

    <div class="card">
      <div class="card-body">
        <ManufacturerUserTable />
      </div>
    </div>
  </div>
</template>
<script>
import ManufacturerUserTable from "@/components/Users/ManufacturerTable.vue";

export default {
  components: { ManufacturerUserTable },
};
</script>
<style></style>
